<template>
    <div class="d-box">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item required label="类型名称" prop="t_name">
                <el-input v-model="form.t_name" placeholder="请输入设备名称（如‘捕蝇灯’）"></el-input>
            </el-form-item>
            <el-form-item required label="检查数据" prop="numArray">
                <el-tag type="warning" closable @close="closeNum(index)"
                 v-for="(it,index) in form.numArray" :key="index">{{it}}</el-tag>
                <div class="input">
                    <el-input placeholder="请输入内容" v-model="numstr"  maxlength="10">
                    <el-button slot="append" @click="addNum">提交</el-button>
                </el-input>
                </div>
            </el-form-item>
            <el-form-item required label="设备区域" prop="areaArray">
                <el-tag type="success" closable @close="closeArea(index)"
                 v-for="(it,index) in form.areaArray" :key="index">{{it}}</el-tag>
                <div class="input">
                    <el-input placeholder="请输入内容" v-model="areastr"  maxlength="10">
                    <el-button slot="append" @click="addArea">提交</el-button>
                </el-input>
                </div>
            </el-form-item>
            <el-form-item required label="操作选择" prop="checkArray">
                <el-tag type="danger" closable @close="closeCheck(index)"
                 v-for="(it,index) in form.checkArray" :key="index">{{it}}</el-tag>
                <div class="input">
                    <el-input placeholder="请输入内容" v-model="checkstr"  maxlength="10">
                    <el-button slot="append" @click="addCheck">提交</el-button>
                </el-input>
                </div>
            </el-form-item>
            <el-form-item required label="巡检规则" prop="rule">
                <el-radio v-model="form.rule" :label="0">无规则</el-radio>
                <el-radio v-model="form.rule" :label="1">全部检查</el-radio>
                最少检查数量:
                <el-input-number v-model="form.rule" 
                @change="handleChange" :min="0" :max="100" label="最少检查数"></el-input-number>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{btnText}}</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        data:{
            type:Object
        }
    },
    data(){
        return{
            btnText:"添加",
            form:{
                update:false,
                t_name:"",
                areaArray:[],
                numArray:[],
                checkArray:[],
                area:'',
                check_data:'',
                num_data:'',
                rule:'0'
            },
            numstr:'',
            areastr:'',
            checkstr:''
        }
    },
    watch:{
        data:{
            immediate:true,
            handler(val){
                if(val&&val.tid){
                    this.form = val
                this.update = true
                this.btnText = "保存修改"
                }
                
            }
        }
    },
    methods:{
        handleChange(num){
            this.form.rule = num
        },
        //重置
         resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let params = this.form 
                    params.method = "addDeviceType"
                    params.num_data = JSON.stringify(params.numArray)
                    params.check_data = JSON.stringify(params.checkArray)
                    params.area = JSON.stringify(params.areaArray)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false 
                        this.$message({
                                message: res.msg||'添加成功',
                                type: 'success'
                            });
                            this.resetForm('form')
                            this.$emit("close")
                    }).catch(err=>{
                        //err
                        console.log(err)
                        this.loading = false
                        this.$message({
                                message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                                type: 'warning'
                            });
                    }) 
                }
            })
        },
        closeNum(index){
            this.form.numArray.splice(index,1)
            this.$forceUpdate()
        },
        addNum(){
            if(this.numstr){
                this.form.numArray.push(this.numstr)
                this.numstr=''
            }
        },closeArea(index){
            //console.log("关闭",index)
            this.form.areaArray.splice(index,1)
            this.$forceUpdate()
        },
        addArea(){
            if(this.areastr){
                this.form.areaArray.push(this.areastr)
                this.areastr=''
            }
        },closeCheck(index){
            this.form.checkArray.splice(index,1)
            this.$forceUpdate()
        },
        addCheck(){
            if(this.checkstr){
                this.form.checkArray.push(this.checkstr)
                this.checkstr=''
            }
        }
    }
}
</script>
<style lang="less">
.d-box{
    .input{
        width:250px;
    }
      .el-tag + .el-tag {
        margin-left: 10px;
    }
}
</style>