<template>
    <div class="dev-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>设备类型</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="addType=true" type="primary" size="small" icon="el-icon-plus" round>添加</el-button>
                </el-form-item>
                <el-form-item label="">
                        <el-autocomplete
                        class="inline-input"
                        v-model="form.keys"
                        :fetch-suggestions="searchType"
                        placeholder="输入关键字"
                        :trigger-on-focus="false"
                        ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-client -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                    prop="t_name"
                    label="设备名称">
                </el-table-column>
                <el-table-column
                    prop="area"
                    label="区域选择">
                    <template slot-scope="scope">
                        <el-tag type="success" v-for="item in scope.row.areaArray" :key="item">{{item}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="num_data"
                    label="检查数据">
                    <template slot-scope="scope">
                        <el-tag type="success" v-for="item in scope.row.numArray" :key="item">{{item}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="check_data"
                    label="操作选择">
                    <template slot-scope="scope">
                        <el-tag type="success" v-for="item in scope.row.checkArray" :key="item">{{item}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    fixed="right"
                    v-if="modifyRule"
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.tid)"></i>
                        <i class="edit el-icon-edit" @click="handleUpadte(scope.row)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- list-client-end -->
        <!-- page分页 -->
        <div class="pageNav" v-if="list.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="form.page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 弹出框 -->
        <el-dialog :title="newData.tid?'更改类型':'添加类型'" :visible.sync="addType">
            <AddDeviceType @close="close" :data="newData"/>
        </el-dialog>
    </div>
</template>
<script>
import AddDeviceType from '@/components/AddDeviceType'
import {checkRules} from '@/utils/util.js'
export default {
    components:{
        AddDeviceType
    },
    data(){
        return{
            addType:false,
            pageSize:10,
            list:[],
            showDel:false,
            totalPage:1,
            addRule:checkRules(6),//添加客户权限
            exportRule:checkRules(8),//导出客户权限
            modifyRule:checkRules(7),//修改客户权限
            loading:false,
            pageArr:[6,10,15,20],
            form:{
                method:'getDeviceList',
                page:1,
                keys:'',
            },
            newData:{}
        }
    },
    created(){
        this.devicetype()
    },
    filters:{
        toArray:function(data){
            return JSON.parse(data)
        }
    },
    methods:{
        close(){
            this.addType=false
            this.newData = {}
            this.devicetype()
        },
        search(){

        },
        currentChange(){

        },
        choisePageSize(){

        },
        handleUpadte(data){
            this.newData = data 
            this.addType = true
        },
        handleDelete(id){
            this.$confirm('确定删除该设备类型?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.delDeviceType(id)
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
            });
        },
        searchType(){

        },
        delDeviceType(id){
            let params = {
                method:"delDevicetype",
                id
            }
            this.$ajax.post(this.API.api,params).then(()=>{
                //todo
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.devicetype()
            }).catch(err=>{
            //err
            console.log(err)
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            }) 
        },
        devicetype(){
            let params = {
                method:"devicetype"
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                this.loading = false 
                if(res.data){
                    this.list = res.data
                    this.list.forEach(item=>{
                        console.log(item)
                        item.numArray = JSON.parse(item.num_data||'[]')
                        item.checkArray = JSON.parse(item.check_data||'[]')
                        item.areaArray = JSON.parse(item.area||'[]')
                    })
                }
            }).catch(err=>{
            //err
                console.log(err)
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            }) 
        }
    }
}
</script>
<style lang="less">
.dev-box{
     .el-tag + .el-tag {
        margin-left: 10px;
        margin-bottom: 5px;
    }
}
</style>